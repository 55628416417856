import React from "react"
import ReactMarkdown from "react-markdown"
import { CircularProgress, TextField, Button, Alert } from "@mui/material"

import { FaThumbsDown } from "react-icons/fa"
import { IoMdClose } from "react-icons/io"
import "./Bot.css"
import rehypeRaw from "rehype-raw"
import { sendFeedback } from "./utils"

function LinkRenderer(props) {
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  )
}

const Bot = ({ botText, loading, conversationId, showFeedback }) => {
  const [feedback, setFeedback] = React.useState(null)
  const [feedbackText, setFeedbackText] = React.useState(null)
  const [sendingFeedback, setSendingFeedback] = React.useState(false)
  const [message, setMessage] = React.useState(null)

  const handleSubmit = async () => {
    setSendingFeedback(true)
    const res = await sendFeedback(conversationId, feedbackText)
    setFeedback(false)
    res?.success
      ? setMessage({ type: "success", text: res?.success })
      : setMessage({ type: "error", text: res?.error })
    setTimeout(() => {
      setMessage(null)
    }, 5000)
  }
  return (
    <div className="div-vertical">
      <div className="bot">
        <img className="bot-logo" src={`${process.env.PUBLIC_URL}${"assets/bot.png"}`} alt="bot" />
        {loading && botText === "" && <CircularProgress size={20} />}
        <div className="bot-text text-area">
          <ReactMarkdown
            children={botText}
            components={{ a: LinkRenderer }}
            rehypePlugins={[rehypeRaw]}
          />
          {showFeedback && (
            <div style={{ margin: "auto", marginBottom: "0", marginLeft: "5px" }}>
              <FaThumbsDown
                className="thumbs-down-icon"
                color="#888"
                size={18}
                onClick={() => setFeedback(true)}
              />
            </div>
          )}
        </div>
      </div>
      {!feedback && message?.type === "success" && (
        <Alert severity="success">{message?.text}</Alert>
      )}
      {feedback && (
        <div className="div-feedback">
          {message?.type === "error" && (
            <Alert sx={{ width: "fit-content", margin: "auto" }} severity="error">
              {message?.text}
            </Alert>
          )}
          <div style={{ display: "flex" }}>
            <TextField
              id="outlined-textarea"
              label="Enter your feedback"
              placeholder="Placeholder"
              multiline
              value={feedbackText ? feedbackText : ""}
              onChange={(e) => setFeedbackText(e.target.value)}
              sx={{ width: "100%" }}
            />
            <IoMdClose
              style={{ cursor: "pointer" }}
              color="#000"
              size={22}
              onClick={() => setFeedback(false)}
            />
          </div>
          <div className="submit-feedback">
            {sendingFeedback ? (
              <CircularProgress size={24} />
            ) : (
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={{ padding: "2px 6px", fontSize: "12px" }}
              >
                Submit
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Bot
