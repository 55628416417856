import React from "react"

import "./User.css"
const User = ({ userText }) => {
  return (
    <div className="user">
      <img className="user-logo" src={`${process.env.PUBLIC_URL}${"assets/user.png"}`} alt="user" />
      <div className="user-text text-area">
        <p>{userText}</p>
      </div>
    </div>
  )
}

export default User
