import React, { useEffect } from "react"
import IconButton from "@mui/material/IconButton"
import { AiOutlineCloseCircle } from "react-icons/ai"
import { CgMaximizeAlt } from "react-icons/cg"
import { TbArrowsMinimize } from "react-icons/tb"
import { Chat } from "./components/Chat"
import "./App.css"
import { validateUser } from "./components/utils"

const App = () => {
  const [showChat, setShowChat] = React.useState(false)
  const [appClass, setAppClass] = React.useState("app")
  const [maximize, setMaximize] = React.useState(false)
  const [internal, setInternal] = React.useState(false)
  const changeMaximize = () => {
    maximize ? setAppClass("app") : setAppClass("app-maximize")
    setMaximize(!maximize)
  }

  const handleAuthentication = async () => {
    // check the t parameter, save it to local storage and remove it from the url
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get("t")
      ? queryParams.get("t")
      : localStorage.getItem("reece_auth_token")
    const userId = queryParams.get("u")
      ? queryParams.get("u")
      : localStorage.getItem("reece_user_id")
    const isEmployee = queryParams.get("e")
      ? queryParams.get("e")
      : localStorage.getItem("reece_employee")
    if (token) {
      const isValid = await validateUser(token)
      if (isValid) {
        localStorage.setItem("reece_auth_token", token)
        localStorage.setItem("reece_user_id", userId)
        localStorage.setItem("reece_employee", isEmployee)
        queryParams.delete("t")
        queryParams.delete("e")
        queryParams.delete("u")
        window.history.replaceState(null, null, window.location.pathname)
      } else {
        window.location.href = "/authentication/signin"
      }
    } else {
      window.location.href = "/authentication/signin"
    }
  }
  useEffect(() => {
    handleAuthentication()
  }, [])
  return (
    <div className={appClass}>
      {showChat ? (
        <ChatBot
          maximize={maximize}
          changeMaximize={changeMaximize}
          setShowChat={setShowChat}
          internal={internal}
          setInternal={setInternal}
        />
      ) : (
        <div onClick={() => setShowChat(true)}>
          <img
            className="chat-icon"
            src="/assets/bot.png"
            alt="chat"
          />
        </div>
      )}
    </div>
  )
}

const ChatBot = ({ maximize, changeMaximize, setShowChat, internal, setInternal }) => {
  const chatContainerMaxHeight = `${window.innerHeight - 20}px`
  const chatContainerMaxWidth = `${Math.min(window.innerWidth, 720) - 20}px`
  const [messageBoxHeight, setMessageBoxHeight] = React.useState("450px")

  useEffect(() => {
    if (maximize) setMessageBoxHeight(`${window.innerHeight - 250}px`)
    else setMessageBoxHeight("450px")
  }, [maximize])

  return (
    <div
      className="chat-container"
      style={{
        width: maximize ? chatContainerMaxWidth : "343px",
        height: maximize ? chatContainerMaxHeight : "600px",
      }}
    >
      <div className="header">
        <img
          className="header-logo"
            src="/assets/bot.png"
          alt="reece"
        />{" "}
        <p style={{ marginLeft: "10px", fontSize: "18px", fontWeight: "bold" }}>
          Reece Chat {internal ? "(INT)" : "(EXT)"}
        </p>
        <IconButton
          sx={{ position: "absolute", right: "40px", cursor: "pointer", marginRight: "15px" }}
          onClick={changeMaximize}
        >
          {maximize ? (
            <TbArrowsMinimize color="white" size={32} />
          ) : (
            <CgMaximizeAlt color="white" size={32} />
          )}
        </IconButton>
        <AiOutlineCloseCircle
          onClick={() => setShowChat(false)}
          style={{ position: "absolute", right: "20px", cursor: "pointer" }}
          color="white"
          size={32}
        />
      </div>
      <Chat messageBoxHeight={messageBoxHeight} internal={internal} setInternal={setInternal} />
    </div>
  )
}

export default App
