import React, { useEffect, useRef, useState } from "react"
import TextField from "@mui/material/TextField"
import IconButton from "@mui/material/IconButton"
import { AiOutlineSend } from "react-icons/ai"
import Bot from "./Bot"
import User from "./User"
import { getConversationId, getBotResponseStream } from "./utils"
import "./Chat.css"

const defaultMessage = {
  type: "bot",
  text: "Hi, welcome to **Reece Chat**. How can I help you?",
}
const errorMessage = "I am sorry, **I am facing some issues**. Please try again."

export const Chat = ({ messageBoxHeight, internal, setInternal }) => {
  const [currentText, setCurrentText] = useState(null)
  const [inputText, setInputText] = React.useState("")
  const [conversationId, setConversationId] = React.useState("")
  const [messages, setMessages] = React.useState([defaultMessage])
  const [isLoading, setIsLoading] = React.useState(false)
  const containerRef = useRef(null)

  const scrollToBottom = () => {
    setTimeout(() => {
      const container = containerRef.current
      container.scrollTop = container.scrollHeight
    }, 250)
  }
  const [debugInfo, setDebugInfo] = React.useState(true)

  const createConversationId = () => {
    getConversationId().then((id) => setConversationId(id))
  }

  useEffect(() => {
    localStorage.setItem("conversation_type", internal ? "internal" : "external")
    createConversationId()
  }, [internal])

  const handleStream = async (reader) => {
    const readStream = async () => {
      try {
        const { done, value } = await reader.read()
        if (done) return ""
        const currentStream = new TextDecoder().decode(value)
        // console.log(currentStream)
        setCurrentText((prevData) => `${prevData}${currentStream}`)
        scrollToBottom()
        return currentStream + (await readStream())
      } catch (error) {
        console.error("An error occurred while reading the stream:", error)
        return ""
      }
    }
    return await readStream()
  }

  const toggleMode = (shouldBeInternal) => {
    setInputText("")
    if (internal === shouldBeInternal) {
      const mode = shouldBeInternal ? "internal" : "external"
      const messageText = `**Already in ${mode} mode**`
      setMessages((messages) => [...messages, { type: "bot", text: messageText }])
      return
    }
    setInternal(shouldBeInternal)
    setMessages([defaultMessage])
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isLoading || !inputText) return
    if (inputText === "/debug") {
      setDebugInfo(!debugInfo)
      setInputText("")
      return
    }
    if (inputText === "/internal") {
      toggleMode(true)
      return
    }
    if (inputText === "/external") {
      toggleMode(false)
      return
    }
    setIsLoading(true)
    setCurrentText("")
    setMessages((messages) => [...messages, { type: "user", text: inputText }])
    scrollToBottom()
    setInputText("")
    const reader = await getBotResponseStream(conversationId, inputText)
    if (reader.response)
      setMessages((prevData) => [...prevData, { type: "bot", text: reader.response }])
    else {
      const data = await handleStream(reader)
      if (data) setMessages((prevData) => [...prevData, { type: "bot", text: data }])
      else setMessages((prevData) => [...prevData, { type: "bot", text: errorMessage }])
    }
    setCurrentText(null)
    scrollToBottom()
    setIsLoading(false)
  }
  const getCountUserMessages = () => messages.filter((message) => message.type === "user").length
  return (
    <div className="chat">
      <div className="messages" ref={containerRef} style={{ height: messageBoxHeight }}>
        {messages.map((message, index) =>
          message.type === "bot" ? (
            <Bot key={index} botText={message.text} conversationId={conversationId} showFeedback={index ? true : false}/>
          ) : (
            <User key={index} userText={message.text} />
          )
        )}
        {currentText !== null && <Bot botText={currentText} loading={true} />}
      </div>
      <div className="bottom-input">
        <form onSubmit={handleSubmit} className="input-form">
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            autoComplete="off"
            style={{ width: "80%" }}
          />
          <IconButton
            type="submit"
            style={{ color: isLoading ? "lightgrey" : "#003766" }}
            aria-label="send"
          >
            <AiOutlineSend size={32} />
          </IconButton>
        </form>
      </div>
      {debugInfo && (
        <div className="debug-info">
          <p>
            Message Count: <b>{getCountUserMessages()} </b>
          </p>
          <p>
            Conversation Id: <b>{conversationId === "" ? "None" : conversationId} </b>
          </p>
        </div>
      )}
    </div>
  )
}
